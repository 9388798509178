import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Corrected import for programmatic navigation
import searchingGif from '../Images/search2.gif';
import mathGif from '../Images/math2.gif';
import treasuremapGif from '../Images/map2.gif';
import pirateGif from '../Images/pirate2.gif';

function RetrieveTransactions() {
  const [loading, setLoading] = useState(true);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const navigate = useNavigate(); // Use useNavigate hook for navigation

  const loadingMessages = [
    <>
    <h2>Hang on while we search for transactions...</h2>
    <img src={searchingGif} alt="Searching..." style={{ width: 250, height: 250 }} />
     </>,
     <>
     <h2>Searching for treasures...</h2>
    <img src={treasuremapGif} alt="Treasure..." style={{ width: 250, height: 250 }} />  
    </>,
    <>
     <h2>Searching far and wide...</h2>
    <img src={pirateGif} alt="Pirate..." style={{ width: 250, height: 250 }} />  
    </>,
    <>
     <h2>Doing advanced math...</h2>
    <img src={mathGif} alt="Math..." style={{ width: 250, height: 250 }} /> 
     </>,

  ];

  useEffect(() => {
    const cdrid = localStorage.getItem('cdrid');
    const headers = {
      'Content-Type': 'application/json',
      'tenantId': 'POKITPAL-DEV',
      'apiKey': 'SBtqonxHdQtXyx4cTnOZg2TOGcNsL4vMMBPeRvcCfH91ZuvYmMmTzGewI5cBHvOE'
    };

    fetch('https://api.dev.cdr.sipora.io/ro/checkconnection', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ cdrid }),
    })
    .then((response) => {
      if (response.status === 201) {
        // Use setTimeout to delay the navigation
        setTimeout(() => {
          setLoading(false);
          navigate('/ShowMissed'); // Navigate after setting loading to false
        }, 12000);
      } else if (response.status === 200) {
        // Handle the successful response as needed
        setTimeout(() => {
          setLoading(false);
          navigate('/ShowMissed'); // Navigate after setting loading to false
        }, 12000);
        // Potentially navigate based on response data
      } else {
        setTimeout(() => {
          setLoading(false);
         return; // Navigate after setting loading to false
        }, 30000);
        
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false);
    });

    const intervalId = setInterval(() => {
      setLoadingMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length);
    }, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate, loadingMessages.length]); // Add navigate to the dependency array

  return (
    <div>
      {loading ? (
        <p>{loadingMessages[loadingMessageIndex]}</p>
      ) : (
        // Content to show after loading is complete
        <div className="footer">'We having some trouble connecting to your bank, please try again later or contact support@pokitpal.com for assistance.​'</div>
      )}
    </div>
  );
}

export default RetrieveTransactions;
