import React, { useEffect, useState } from 'react';
import './shared.css';
import Logo from './Images/pokitpal_logo_white.png';
import ReactGA from 'react-ga4';
import MissedRewards from './Forms/MissedRewards';
import RewardsList from './Forms/RewardsList';


function ShowMissed() {

      const [isSubmitting, setIsSubmitting] = useState(false);
      const [textToShow, setTextToShow] = useState('')
   
      useEffect(() => {
       ReactGA.send({ hitType: "pageview", page: window.location.pathname });
       }, []);
      
      

      const handleDataLoaded = (totalCashBack) => {
        console.log('received total cashback:', totalCashBack);
    
        const goal = localStorage.getItem('goal');
        chooseTextToShow(goal, totalCashBack);
    };
       
        const chooseTextToShow = (goal, totalCashBackNumber) => {

          let newText = 'We found your missing money!'

        if (goal === 'save money') {
          newText = 'You could have saved so much!';
        } 
        else  if (goal === 'pay off my debt') {
          newText = 'You could have reduced your debt by heaps!';
        } 
        else  if (goal === 'buy a house') {
          newText = 'This would be great toward your home deposit';
        } 
        else  if (goal === 'go on a dream trip') {
          if (totalCashBackNumber > 10000) {
            newText = 'You could have flown return business to EUROPE!';
          }
          else if (totalCashBackNumber > 5000) {
            newText = 'You could have flown return to EUROPE!';
          }
          else if (totalCashBackNumber > 2000) {
            newText = 'You could have flown return to ASIA!';
          }
          else if (totalCashBackNumber > 1000) {
            newText = 'You could have gone on a weekend away!';
          }
          else if (totalCashBackNumber < 499)
          {
            newText = 'You could have gone on a weekend away';
          }
        } 
      
        setTextToShow(newText)
        console.log(newText);
};
   

        const handleClick = () => {
        setIsSubmitting(true);
      window.location.href = 'https://onelink.to/gwjcm7'
      };   
    



return (
<div className="container">
<div className="logo-box">
    {<img src={Logo} alt="Logo" className="logo-image" />}
        
      </div>
  <div className="header">
  <h1 className="title">WOAH!</h1>
<h1 className="title">{textToShow}</h1>
  </div>
  {<MissedRewards onDataLoaded={handleDataLoaded}/>}

  <p className="title">From shopping at your favourite stores:</p>

  {<RewardsList />}
  <p className="title" align="center">Join our Rewards Program now to earn all the cashback you deserve.</p>

  <button onClick={handleClick} className="submit-email-button" disabled={isSubmitting}>
        {isSubmitting ? 'Redirecting...' : `Join Rewards`}</button>

</div>

)
}

export default ShowMissed;